import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "./Navbar.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { alpha, Dialog, Fab, Grid, Menu, MenuItem, Popover, styled, useMediaQuery } from "@mui/material";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import metaProvider from '../../assests/LandingPage/meta-provider.svg'
import BookAMeetingDialog from "../../Utils/GlobalDialog/BookAMeetingDialog/BookAMeetingDialog";

import chronoxWhiteLogo from "../../assests/GlobalImages/Chronox-White-Logo.svg"
import ChronoxPrimaryLogo from "../../assests/LandingPage/ChronoxNewLogo.svg"
import menu from "../../assests/GlobalImages/Navbar-menu.svg"
import whatsppimage from '../../assests/Whatsapp.svg'
import botIcon from './../../assests/LandingPage/chatbotProfileAvtar.png'
import messangerimage from '../../assests/Chronox-integration.svg'
import messengerIcon from './../../assests/FacebookChatbot/facebookMessenger.svg'
import CustomAIChatbotDialog from "../../Utils/GlobalDialog/CustomAIChatbotDialog/CustomAIChatbotDialog";
import { Close, TurnedInOutlined } from "@mui/icons-material";

import BannerComponent from "../../Component/BannerComponent/BannerComponent";
import MobileDrawer from "./MobileDrawer/MobileDrawer";
import NavbarNastedMenu from "./NavbarNastedMenu/NavbarNastedMenu";

import aiAgent from '../../assests/Navbar/AI agent.svg'
import interative from '../../assests/Navbar/Interactive messages.svg'
import rag from '../../assests/Navbar/RAG & AI Actions.svg'
import callAnalysis from '../../assests/Navbar/Call analysis.svg'
import omnichannel from '../../assests/Navbar/Omnichannel Deployment.svg'
import integration from '../../assests/Navbar/Integration.svg'
import liveAgent from '../../assests/Navbar/Live agent assist.svg'
import voiceAI from '../../assests/Navbar/Voice AI.svg'

import Calendly from '../../assests/Navbar/calendly.svg'
import email from '../../assests/Navbar/email.svg'
import facebook from '../../assests/Navbar/facebook.svg'
import instagram from '../../assests/Navbar/instagram.svg'
import whatsapp from '../../assests/Navbar/whatsapp.svg'
import Website from '../../assests/Navbar/Website.svg'
import trello from '../../assests/Navbar/trello.svg'
import slack from '../../assests/Navbar/slack.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import healthCare from '../../assests/Navbar/HealthCare.svg'
import food from '../../assests/Navbar/Food.svg'
import retail from '../../assests/Navbar/Retail Sector.svg'
import ecommerce from '../../assests/Navbar/E Commerce.svg'
import blog from '../../assests/Navbar/Blog.svg'
import BookACalendlyDialog from "../../Utils/GlobalDialog/BookACalendlyDialog/BookACalendlyDialog";

import techExpoIcon from './../../assests/GlobalImages/tech-expo-nav-icon.svg';
import gitexIcon from './../../assests/GlobalImages/gitex-nav-logo.png';

const Navbar = (props) => {
  const { flagBanner, setFlagBanner } = props


  // CONST
  const navigate = useNavigate();
  const writeWizWebsiteURL = process.env.REACT_APP_WEBSITE_REDIRECT_URL;
  const webPageName = window.location.pathname.replace('/', '')
  const isTablateWidth = useMediaQuery('(min-width: 0px) and (max-width: 900px')
  const isMobile = useMediaQuery('(min-width: 0px) and (max-width: 600px');
  const removeBookMeeting = useMediaQuery('(min-width: 0px) and (max-width: 1150px');
  const navbarRef = useRef(null);
  const bannerRef = useRef(null);
  const leaveTimeout = useRef(null);

  // LOCAL VARIABLE
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [flagOpenHelpCenterDialog, setFlagOpenHelpCenterDialog] = useState(false);
  const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const [isOnHoverNavbar, setIsOnHoverNavbar] = useState(false);
  const [isOnHoverIntegration, setisOnHoverIntegration] = useState(false);
  const [flagPopoverHover, setFlagPopoverHover] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [onMouseHoverMenu, setOnMouseHoverMenu] = useState(false)
  const [navbarHeight, setNavbarHeight] = useState(0);
  const [bannerHeight, setBannerHeight] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const [hover, setHover] = useState(false);


  const menuData = {
    Platform: {
      sections: [
        {
          title: '',
          content: [
            {
              image: aiAgent,
              name: 'AI Agent',
              nameColor: '#6A097D',
              bgColor: '#5E35B112',
              description: 'Beyond chat—AI Agents that act, solve, and transform your business at scale',
              navigationLink: "ai-chatbot"
            },
            {
              image: omnichannel,
              name: 'Omnichannel Deployment',
              nameColor: '#C3236E',
              bgColor: '#C3236E0D',
              description: 'Get insights that highlight what’s working and where to improve.',
              navigationLink: "omnichannel-deployment"
            },
            {
              image: interative,
              name: 'Interactive Messages',
              nameColor: '#14CF8B',
              bgColor: '#DDF4ED99',
              description: 'Engage customers with interactive chats that feel personal.',
              navigationLink: "interactive-message"
            },
            {
              image: integration,
              name: 'Integration',
              nameColor: '#F37C00',
              bgColor: '#FFE0AE4D',
              description: 'Seamlessly integrate AI capabilities into your existing systems.',
              navigationLink: ""
            },
            {
              image: rag,
              name: 'RAG & AI Actions',
              nameColor: '#3233A3',
              bgColor: '#BAB3E226',
              description: 'Leverage real-time AI-driven actions and responses to improve customer satisfaction.',
              navigationLink: ""
            },
            {
              image: liveAgent,
              name: 'Live Agent Assist',
              nameColor: '#1B1B1B',
              bgColor: '#E9E9E980',
              description: 'Empower agents with real-time AI support for more effective resolutions.',
              navigationLink: "",
            },
            {
              image: callAnalysis,
              name: 'Call Analysis',
              nameColor: '#00796B',
              bgColor: '#00796B12',
              description: 'Analyze customer calls to gather insights and improve service quality.',
              navigationLink: ""
            },
            {
              image: voiceAI,
              name: 'Voice AI',
              chip: "Coming Soon",
              chipColor: "#6A097D",
              chipBgColor: "#F7E9FF",
              nameColor: '#3E9DA1',
              bgColor: '#BFF5F64D',
              description: 'Enhance customer interactions with cutting-edge voice AI technology.',
              navigationLink: ""
            },

          ]
        }
      ]
    },
    Solutions: {
      sections: [
        {
          title: 'AI Agent Deployment Channel',
          content: [
            {
              image: Website,
              name: 'Website',
              nameColor: '#6A097D',
              bgColor: '#F7E9FF99',
              description: 'Deploy AI-powered chatbots directly on your website for seamless customer support.',
              navigationLink: "website-ai-chatbot"
            },
            {
              image: facebook,
              name: 'Facebook',
              nameColor: '#0084FF',
              bgColor: '#0084FF12',
              description: 'Automate customer service on Facebook using intelligent AI chatbots.',
              navigationLink: "facebook-ai-chatbot"
            },
            {
              image: whatsapp,
              name: 'WhatsApp',
              nameColor: '#25D366',
              bgColor: '#25D3661A',
              description: 'Engage customers on WhatsApp with real-time AI-driven conversations.',
              navigationLink: "whatsapp-ai-chatbot"
            },
            {
              image: instagram,
              name: 'Instagram',
              nameColor: '#FFD521',
              bgColor: '#D3297812',
              description: 'Connect with customers on Instagram through AI-powered messaging.',
              navigationLink: "instagram"
            },
            {
              image: slack,
              name: 'Slack',
              nameColor: '#00BCD4',
              bgColor: '#FD8E0712',
              description: 'Integrate AI chatbots into Slack to streamline team and customer communication.',
              navigationLink: "slack-ai-chatbot"
            },
            {
              image: email,
              name: 'Email',
              nameColor: '#1E55BA',
              bgColor: '#1E55BA12',
              description: 'Enhance email communications with AI-driven automated responses.',
              navigationLink: ""
            },
          ]
        },
        {
          title: 'Integrations',
          content: [
            {
              image: Calendly,
              name: 'Calendly',
              nameColor: '#6451F4',
              bgColor: '#F1F0F9B2',
              description: 'Integrate with Calendly to automate meeting scheduling through AI chatbots.',
              navigationLink: ""
            },
            {
              image: whatsapp,
              name: 'WhatsApp',
              nameColor: '#25D366',
              bgColor: '#EAFBF0',
              description: 'Enhance your WhatsApp experience with advanced AI integrations.',
              navigationLink: "whatsapp-ai-chatbot"
            },
            {
              image: slack,
              name: 'Slack',
              nameColor: '#00BCD4',
              bgColor: '#FFF7ED',
              description: 'Enable seamless team collaboration with AI integration in Slack.',
              navigationLink: "slack-ai-chatbot"
            },
            {
              image: trello,
              name: 'Trello',
              nameColor: '#518FE1',
              bgColor: '#EDF6FF',
              description: 'Integrate AI into Trello for better task management and team collaboration.',
              navigationLink: ""
            },
            {
              image: email,
              name: 'Email',
              nameColor: '#1E55BA',
              bgColor: '#EFF3FA',
              description: 'Automatically manage email responses with AI to improve productivity.',
              navigationLink: ""
            },
          ]
        }
      ]
    },
    Industries: {
      sections: [
        {
          title: '',
          content: [
            {
              image: healthCare,
              name: 'Healthcare',
              nameColor: '#6A097D',
              bgColor: '#F7E9FF99',
              description: 'Revolutionize patient engagement and support with AI-driven healthcare solutions.',
              navigationLink: "industry/healthcare"
            },
            {
              image: food,
              name: 'Food Sector',
              nameColor: '#0084FF',
              bgColor: '#0084FF12',
              description: 'Streamline order management and customer service with AI in the food industry.',
              navigationLink: "industry/food"
            },
            {
              image: retail,
              name: 'Retail',
              nameColor: '#25D366',
              bgColor: '#25D3661A',
              description: 'Enhance customer shopping experiences with AI-powered retail automation.',
              navigationLink: "industry/retail"
            },
            {
              image: ecommerce,
              name: 'E-Commerce',
              nameColor: '#FFD521',
              bgColor: '#D3297812',
              description: 'Boost sales and customer satisfaction with intelligent AI for e-commerce platforms.',
              navigationLink: "industry/ecommerce"
            },
            {
              image: blog,
              name: 'Blogs',
              nameColor: '#FFD521',
              bgColor: '#D3297812',
              description: 'Automate content interaction and audience engagement for your blogs using AI.',
              navigationLink: "blog"
            },
          ]
        }
      ]
    },
    Events: {
      sections: [
        {
          title: '',
          content: [
            {
              image: techExpoIcon,
              name: 'TECH-EXPO GUJARAT 2024',
              nameColor: '#6A097D',
              bgColor: '#F7E9FF99',
              description: "Gujarat's Largest Tech Expo with Industry Leaders - GUJARAT (December 20 - 21, 2024)",
              navigationLink: "events/tech-expo-gujarat"
            },
            {
              image: gitexIcon,
              name: 'GITEX',
              nameColor: '#0084FF',
              bgColor: '#0084FF12',
              description: 'GITEX GLOBAL 2024 - The Largest Tech & Startup Event In The World - Dubai',
              navigationLink: "events/chronox-at-gitex-global-dubai"
            }
          ]
        }
      ]
    }
  };

  // USE EFFECTS
  useEffect(() => {
    window.addEventListener("scroll", changeStructure);
    return () => {
      window.removeEventListener("scroll", changeStructure);
    };
  }, []);

  useEffect(() => {
    const bannerHeight = bannerRef.current ? bannerRef.current.clientHeight : 0;

    const navHeight = navbarRef.current ? navbarRef.current.clientHeight : 0;
    console.log("bannerHeight", bannerHeight, navHeight);
    // const bannerHeights = flagBanner ? 85 : 0;
    setNavbarHeight(navHeight + bannerHeight);
    setTimeout(() => {
      setBannerHeight(bannerHeight)
    }, 300);
  }, [flagBanner, onMouseHoverMenu, bannerHeight]);

  // FUNCTIONS
  const changeStructure = () => {
    if (window.scrollY > 90) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseEnter = (menu) => {
    if (leaveTimeout.current) {
      clearTimeout(leaveTimeout.current);
    }
    setActiveMenu(menuData[menu]);
    setHover(menu);
  };

  const handleMouseLeave = () => {
    leaveTimeout.current = setTimeout(() => {
      setActiveMenu(null);
      setHover(null);
    }, 300);
  };


  return (
    <>
      {flagBanner &&
        <div>
          <BannerComponent setFlagBanner={setFlagBanner} bannerRef={bannerRef} />
        </div>
      }
      <div className="main-navbar-wrapper" style={{ position: 'relative' }} >

        {activeMenu &&
          <NavbarNastedMenu
            navbarHeight={navbarHeight}
            menu={activeMenu}
            clearMenu={() => { setActiveMenu(null); setHover(null) }}
            cancelClose={() => clearTimeout(leaveTimeout.current)}
          />
        }


        {
          flagOpenBookaMeetingDemo &&
          <Dialog open={flagOpenBookaMeetingDemo}
            maxWidth='lg'
            sx={{ '&.MuiPaper-root': { width: '100%' } }}
            className="iframe-dialog"
          >
            <BookACalendlyDialog
              flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
              setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
            />
          </Dialog>
        }


        <Box className="navbar-box">
          <CssBaseline />
          <AppBar
            ref={navbarRef}
            component="nav"
            // className={isScroll ? (isMobile ? "wz-header-scroll-mobile" : "wz-header-scroll") : (isMobile ? "wz-header-mobile" : "wz-header")}
            className={isMobile ? "wz-header-mobile" : "wz-header"}
            sx={{
              padding: "0px 3px",
              alignItems: "center",
              // top: isTablateWidth && flagBanner ? "138px" : flagBanner ? "98px" : isScroll ? "8px" : "20px"
              top:
                bannerHeight && (isTablateWidth && flagBanner) ? bannerHeight + 5 :
                  (!isTablateWidth && flagBanner) ? bannerHeight + 10 :
                    (isTablateWidth) ? bannerHeight :
                      (flagBanner) ? bannerHeight :
                        (isScroll) ? bannerHeight + 10 : bannerHeight + 10
            }}
          >
            <Toolbar className="wz-header-toolbar" >
              <Grid container className="nav-menu-item-main-container">
                <Grid item
                  className="nav-logo-item"
                  // xs={isTablateWidth ? 12 : 2}
                  sx={{ display: isTablateWidth ? 'flex' : '  ' }}
                >
                  <Grid container sx={{ justifyContent: isTablateWidth ? 'space-between' : '' }}>
                    <Grid item className="nav-logo-wrapper">
                      <Grid container sx={{ alignItems: 'center', height: '100%' }}>
                        <a
                          onClick={() => { navigate('/') }} target="_self">
                          <img
                            src={ChronoxPrimaryLogo}
                            alt="Chronox"
                            style={{ cursor: "pointer", verticalAlign: 'middle', }}
                            className="chronox-logo"
                          />
                        </a>
                      </Grid>
                    </Grid>

                    <Grid item sx={{ justifyContent: isTablateWidth ? 'end' : '' }}>
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        className="navbar-drawer-button"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, color: "black" }}
                      >
                        <MenuIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>

                {!isTablateWidth ?
                  <Grid item
                    // xl={11} lg={11.5} xs={9} md={9.5}
                    className="nav-btn-menu-item"
                  >
                    <Box
                      className={
                        props.page ? props.scrollEffect ? "navbar-computer-right-button-group" : "navbar-computer-right-button-group-with-hidden-button" : "navbar-computer-right-button-group-without-transition"
                      }
                      sx={{ overflow: !props.scrollEffect ? "visible" : "hidden" }}
                    >
                      <Grid item>
                        <Grid container sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                          <Grid item>
                            <Grid container>
                              <Grid item>
                                <Button
                                  id="demo-customized-button"
                                  aria-controls={open ? 'demo-customized-menu' : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={open ? 'true' : undefined}
                                  variant="text"
                                  disableElevation
                                  onClick={(e) => {
                                    handleClick(e)
                                  }}
                                  onMouseEnter={() => { handleMouseEnter('Platform') }}
                                  onMouseLeave={(e) => { handleMouseLeave() }}
                                  className="option-navbar-btn"
                                  sx={{
                                    backgroundColor: activeMenu === 'Platform' || hover === 'Platform' ? "#F7E9FF !important" : "#ffffff"
                                  }}
                                  endIcon={
                                    <KeyboardArrowDownIcon
                                      className="nav-button-key-arrow"
                                      sx={{ transform: activeMenu === 'Platform' || hover === 'Platform' ? 'rotate(180deg)' : 'rotate(0deg)', }}
                                    />
                                  }
                                >
                                  Platform
                                </Button>
                              </Grid>

                              <Grid item>
                                <Button
                                  variant="text"
                                  className="option-navbar-btn"
                                  onMouseEnter={() => handleMouseEnter('Solutions')}
                                  onMouseLeave={(e) => handleMouseLeave()}
                                  onClick={() => { navigate('/') }}
                                  sx={{
                                    background: activeMenu === 'Solutions' || hover === 'Solutions' ? "#F7E9FF !important" : "#ffffff"
                                  }}
                                  endIcon={
                                    <KeyboardArrowDownIcon
                                      className="nav-button-key-arrow"
                                      sx={{ transform: activeMenu === 'Solutions' || hover === 'Solutions' ? 'rotate(180deg)' : 'rotate(0deg)', }}
                                    />
                                  }
                                >
                                  Solutions
                                </Button>
                              </Grid>

                              <Grid item>
                                <Button
                                  variant="text"
                                  className="option-navbar-btn"
                                  onMouseEnter={() => handleMouseEnter('Industries')}
                                  onMouseLeave={(e) => handleMouseLeave()}
                                  onClick={() => { navigate('/') }}
                                  sx={{
                                    background: activeMenu === 'Industries' || hover === 'Industries' ? "#F7E9FF !important" : "#ffffff"
                                  }}
                                  endIcon={
                                    <KeyboardArrowDownIcon
                                      className="nav-button-key-arrow"
                                      sx={{ transform: activeMenu === 'Industries' || hover === 'Industries' ? 'rotate(180deg)' : 'rotate(0deg)', }}
                                    />
                                  }
                                >
                                  Industries
                                </Button>
                              </Grid>

                              <Grid item>
                                <Button
                                  variant="text"
                                  className="option-navbar-btn"
                                  onMouseLeave={() => setIsOnHoverNavbar(false)}
                                  onMouseEnter={() => setIsOnHoverNavbar(true)}
                                  onClick={() => { navigate('/chatbot-pricing') }}
                                >
                                  Pricing
                                </Button>
                              </Grid>

                              <Grid item>
                                <Button
                                  variant="text"
                                  className="option-navbar-btn"
                                  onMouseLeave={() => setIsOnHoverNavbar(false)}
                                  onMouseEnter={() => setIsOnHoverNavbar(true)}
                                  onClick={() => { navigate('/contact-us') }}
                                >
                                  Contact
                                </Button>
                              </Grid>

                              <Grid item>
                                <Button
                                  variant="text"
                                  className="option-navbar-btn"
                                  onMouseEnter={() => handleMouseEnter('Events')}
                                  onMouseLeave={(e) => handleMouseLeave()}
                                  onClick={() => { navigate('/') }}
                                  sx={{
                                    background: activeMenu === 'Events' || hover === 'Events' ? "#F7E9FF !important" : "#ffffff"
                                  }}
                                  endIcon={
                                    <KeyboardArrowDownIcon
                                      className="nav-button-key-arrow"
                                      sx={{ transform: activeMenu === 'Events' || hover === 'Events' ? 'rotate(180deg)' : 'rotate(0deg)', }}
                                    />
                                  }
                                >
                                  Events
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item>
                            <Grid container gap={{xs: "10px", md: "0px", lg: "10px"}}>
                              <Grid item>
                                <Button
                                  variant="span"
                                  size="large"
                                  className="login-btn"
                                  onClick={() => window.open(`${process.env.REACT_APP_REDIRECT_URL}`, '_blank')}
                                >
                                  Login
                                </Button>
                              </Grid>
                              {!removeBookMeeting &&
                                <Grid item>
                                  <Button
                                    key={"Get Demo"}
                                    variant={"outlined"}
                                    sx={{
                                      marginLeft: "10px !important",
                                      minWidth: "40px",
                                      color: '#6A097D',
                                      background: 'white',
                                      borderRadius: '5px',
                                      border: '1px solid #6A097D',
                                      fontSize: '15px',
                                      fontWeight: '600',
                                      textTransform: 'none',
                                      padding: '5px 20px',
                                      '&:hover': {
                                        borderColor: '#6A097D',
                                      }
                                    }}
                                    onClick={() => { setFlagOpenBookaMeetingDemo(true) }}
                                  >
                                    Book a Meeting
                                  </Button>
                                </Grid>
                              }
                              <Grid item>
                                <Button
                                  key={"Blog"}
                                  variant={"text"}
                                  sx={{
                                    marginLeft: "10px !important",
                                    minWidth: "40px",
                                    color: 'white',
                                    background: '#6A097D',
                                    borderRadius: '5px',
                                    border: '1px solid #6A097D',
                                    fontSize: '15px',
                                    fontWeight: '600',
                                    textTransform: 'none',
                                    padding: '5px 20px',
                                  }}
                                  className="navbar-contact-us"
                                  onClick={(event) => {
                                    const url = process.env.REACT_APP_REDIRECT_URL;
                                    if (event.ctrlKey || event.metaKey) {
                                      window.open(url, "_blank");
                                    } else {
                                      window.location.href = url;
                                    }

                                  }}
                                >
                                  Get Started
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid> : (<></>)
                }
              </Grid>
            </Toolbar>
          </AppBar>

          <Box component="nav" className="mobileHamburgerSideBar-parent-div">
            <div>
              <Drawer
                variant="persistent"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                anchor="right"
                ModalProps={{
                  keepMounted: true,
                }}
                sx={{
                  "& .MuiDrawer-paper": {
                    backgroundColor: "#ffffff",
                  },
                }}
              >
                <Grid container sx={{ height: '100%', zIndex: 10 }}>
                  <Grid item xs={12} sx={{ padding: '15px', height: '100%' }}>
                    <MobileDrawer
                      handleDrawerToggle={handleDrawerToggle}
                    />
                  </Grid>
                </Grid>
              </Drawer>
            </div>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Navbar;
