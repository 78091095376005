import React from 'react'
import './TechExpoTimerComponent.css'
import { Button, Grid } from '@mui/material'
import tecgExpoFront from './../../assests/GlobalImages/tech-expo-front.png'
import techExpoIcon from './../../assests/GlobalImages/tech-expo-icon.png'
import ChronoxPrimaryLogo from "../../assests/GlobalImages/chronox-white-new-logo.svg"

const TechExpoTimerComponent = () => {

    const [fadeIn, setFadeIn] = React.useState(false);
    React.useEffect(() => {
        setFadeIn(true);
    }, []);

    return (
        <Grid item className={fadeIn ? 'tech-expo-main-container-style agentImageStyles fadeInClass' : 'tech-expo-main-container-style agentImageStyles'}>
            <Grid container sx={{height: "100%"}} justifyContent={"center"} alignItems={"center"}>
                <Grid item className='tech-expo-sub-container-style'>
                    <Grid container alignItems={"center"} gap={3} flexWrap={{xs: "wrap", md: "nowrap"}} sx={{height: "100%"}} justifyContent={"center"}>
                        <Grid item xs={12} lg={5.5} xl={6}>
                            <Grid container gap={2}>
                                <Grid item xs={12}>
                                    <span className='tech-expo-heading-style'>TECH-EXPO GUJARAT 2024</span>
                                </Grid>
                                <Grid item xs={12}>
                                    <span className='tech-expo-sub-heading-style'>Gujarat's Largest Tech Expo with Industry Leaders</span>
                                </Grid>
                                <Grid item xs={12} lg={11} xl={10}>
                                    <span className='tech-expo-sub-content-style'>Tech Expo Gujarat 2024 is an innovative 2 days event happening in Ahmedabad, Gujarat. Get ready to experience leading-edge technological advancements, informative conferences, and engage with industry experts.</span>
                                </Grid>
                                <Grid item xs={12} mt={1}>
                                    <span className='tech-expo-sub-content-style'>Date: December 20 - 21, 2024 Time: 9 AM - 6 PM</span>
                                </Grid>
                                <Grid item xs={12} xl={9}>
                                    <span className='tech-expo-sub-content-style'>Location: Vigyan Bhawan Science City,<br /> @ Booth No. D - 6 Ahmedabad, Gujarat, India</span>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button className='tech-expo-contact-us-button' onClick={() => window.open(`/contact-us`, '_blank')}>Contact Us</Button>
                                </Grid>
                                <Grid item xs={12} mt={3}>
                                    <Grid container gap={4} alignItems={"center"}>
                                        <Grid item>
                                            <img src={techExpoIcon} alt="" />
                                        </Grid>
                                        <Grid item>
                                            <img src={ChronoxPrimaryLogo} width={"170"}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} lg={6.5} xl={6} textAlign={"center"}>
                            <img src={tecgExpoFront} alt="" className='tech-expo-right-image-style'/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TechExpoTimerComponent
