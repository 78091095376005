import { Button, Dialog, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import './OnlineDemoSection.css'
import BookAMeetingDialog from '../../Utils/GlobalDialog/BookAMeetingDialog/BookAMeetingDialog';
import BookACalendlyDialog from '../../Utils/GlobalDialog/BookACalendlyDialog/BookACalendlyDialog';


const OnlineDemoSection = (props) => {
    const [flagOpenBookaMeetingDemo, setFlagOpenBookaMeetingDemo] = useState(false);
    const {eventName} = props;

    return (
        <>
            {flagOpenBookaMeetingDemo && (
                <Dialog open={flagOpenBookaMeetingDemo}
                    maxWidth='lg'
                    sx={{ '&.MuiPaper-root': { width: '100%' } }}
                    className="iframe-dialog"
                >
                    {/* <BookAMeetingDialog
                        flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                        setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                    /> */}
                    <BookACalendlyDialog
                        flagOpenBookaMeetingDemo={flagOpenBookaMeetingDemo}
                        setFlagOpenBookaMeetingDemo={setFlagOpenBookaMeetingDemo}
                    />
                </Dialog>
            )}
            <Grid container>
                <Grid item xs={12} className='ods-card-main-item'>
                    <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }} direction={"column"} gap={"15px"}>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Typography variant='span' className='ods-main-head-text'>
                                {`Not Coming to ${eventName} this year?`}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Typography variant='span' className='ods-main-subhead-text'>
                                Request an Online Demo
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center' }} className='ods-main-title-grid'>
                            <Typography variant='span' className='ods-main-title-text'>
                                {`if you're unable to attend ${eventName} this year, click here to request an online demo with our sales team`}
                            </Typography>
                        </Grid>
                        <Grid item sx={{ margin: '10px 0px 10px' }}>
                            <Button
                                variant='contained'
                                size='large'
                                className='ods-request-demo-btn'
                                onClick={() => { setFlagOpenBookaMeetingDemo(true) }}
                            >
                                Request an Online Demo
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default OnlineDemoSection